import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import app from '../firebase';
import './GameNightSignupModal.css';

function GameNightSignupModal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    instagram: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [activeField, setActiveField] = useState(null);
  const [animateIn, setAnimateIn] = useState(false);
  
  // Animation effect when modal opens
  useEffect(() => {
    if (isOpen) {
      // Slight delay to allow CSS transition to work properly
      setTimeout(() => setAnimateIn(true), 50);
    } else {
      setAnimateIn(false);
    }
  }, [isOpen]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  
  const handleFocus = (field) => {
    setActiveField(field);
  };
  
  const handleBlur = () => {
    setActiveField(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const db = getFirestore(app);
      await addDoc(collection(db, 'gameNightSignups'), {
        ...formData,
        timestamp: new Date(),
      });

      setSubmitStatus('success');
      setTimeout(() => {
        window.open('https://www.instagram.com/tutto.play/', '_blank');
        onClose();
        setFormData({ name: '', email: '', phone: '', instagram: '' });
        setSubmitStatus(null);
      }, 2000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }
    setIsSubmitting(false);
  };

  if (!isOpen) return null;

  return (
    <div className={`game-night-modal-overlay ${animateIn ? 'active' : ''}`}>
      <div className={`game-night-modal ${animateIn ? 'active' : ''}`}>
        <button 
          className="close-button" 
          onClick={onClose}
          aria-label="Close modal"
        >
          &times;
        </button>
        <div className="modal-header">
          <img 
            src="/image.png"
            alt="tuttO Game Night Preview" 
            className="preview-image"
          />
          <h2 className="modal-title">Join Our Game Nights</h2>
          <p className="modal-subtitle">Be first to know about upcoming tuttO events!</p>
        </div>
        
        <form onSubmit={handleSubmit} className="signup-form">
          <div className={`form-group ${activeField === 'name' ? 'active' : ''} ${formData.name ? 'filled' : ''}`}>
            <label htmlFor="name" className="form-label">Your Name</label>
            <input
              id="name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              onFocus={() => handleFocus('name')}
              onBlur={handleBlur}
              placeholder="Your Name"
              required
            />
          </div>
          <div className={`form-group ${activeField === 'email' ? 'active' : ''} ${formData.email ? 'filled' : ''}`}>
            <label htmlFor="email" className="form-label">Email Address</label>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              onFocus={() => handleFocus('email')}
              onBlur={handleBlur}
              placeholder="Email Address"
              required
            />
          </div>
          <div className={`form-group ${activeField === 'phone' ? 'active' : ''} ${formData.phone ? 'filled' : ''}`}>
            <label htmlFor="phone" className="form-label">Phone Number</label>
            <input
              id="phone"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              onFocus={() => handleFocus('phone')}
              onBlur={handleBlur}
              placeholder="Phone Number"
              required
            />
          </div>
          <div className={`form-group ${activeField === 'instagram' ? 'active' : ''} ${formData.instagram ? 'filled' : ''}`}>
            <label htmlFor="instagram" className="form-label">Instagram Handle</label>
            <input
              id="instagram"
              type="text"
              name="instagram"
              value={formData.instagram}
              onChange={handleChange}
              onFocus={() => handleFocus('instagram')}
              onBlur={handleBlur}
              placeholder="Instagram Handle"
            />
          </div>
          <button 
            type="submit" 
            className={`submit-button ${isSubmitting ? 'submitting' : ''}`}
            disabled={isSubmitting}
          >
            <span className="button-text">{isSubmitting ? 'Joining...' : 'Join List'}</span>
            <span className="button-icon">
              {isSubmitting ? (
                <svg className="spinner" viewBox="0 0 50 50">
                  <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                </svg>
              ) : (
                <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              )}
            </span>
          </button>
          
          {submitStatus === 'success' && (
            <div className="status-message success">
              <svg className="status-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                <polyline points="22 4 12 14.01 9 11.01"></polyline>
              </svg>
              <span>Successfully joined! Redirecting you to follow tutto on Instagram...</span>
            </div>
          )}
          {submitStatus === 'error' && (
            <div className="status-message error">
              <svg className="status-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="12"></line>
                <line x1="12" y1="16" x2="12.01" y2="16"></line>
              </svg>
              <span>Oops! Something went wrong. Please try again.</span>
            </div>
          )}
          
          <div className="form-footer">
            <p>Join us for a night of fun, flirting, and connections!</p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default GameNightSignupModal; 
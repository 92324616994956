import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import BuyNowModal from './components/BuyNowModal';
import GameNightSignupModal from './components/GameNightSignupModal';

// Manually set the number of images in each folder
const TOTAL_QUESTIONS = 14;
const TOTAL_ANSWERS = 18;

const reviews = [
  { text: '"I played the game its awesome and so relatable especially Tesla stocks and carbone spicy rigatoni 😂"' },
  { text: '"The ultimate vibe maker for dates and parties. 10/10 would embarrass myself again!"' },
  { text: '"My friends and I cant stop playing. So many unforgettable moments!"' },
];

function App() {
  const [showCards, setShowCards] = useState(false);
  const [questionCard, setQuestionCard] = useState(1);
  const [answerCards, setAnswerCards] = useState([1, 2, 3, 4]);
  const [eventImages, setEventImages] = useState([]);
  const [currentReview, setCurrentReview] = useState(0);
  const [isChanging, setIsChanging] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGameNightModalOpen, setIsGameNightModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();

  // Fix for ResizeObserver error
  useEffect(() => {
    const errorHandler = (event) => {
      // Only prevent ResizeObserver errors from being logged
      if (event.message && event.message.includes('ResizeObserver')) {
        event.stopImmediatePropagation();
      }
    };
    
    window.addEventListener('error', errorHandler);
    
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  // Effect to handle /join route
  useEffect(() => {
    if (location.pathname === '/join') {
      setIsGameNightModalOpen(true);
    }
  }, [location.pathname]);

  const handleBuyNowClick = () => {
    setIsModalOpen(true);
  };

  const handleModalBuyNow = () => {
    const stripeLink = location.pathname === '/game' 
      ? 'https://buy.stripe.com/28ocN1aAo3Mz32U3cg'
      : 'https://buy.stripe.com/cN26oDgYMbf17jaeUZ';
    
    window.location.href = stripeLink;
  };

  useEffect(() => {
    const cardTimer = setTimeout(() => setShowCards(true), 500);

    // Load event images
    const eventImagesContext = require.context('../public/Events', false, /\.(png|jpe?g|svg)$/);
    const images = eventImagesContext.keys().map(eventImagesContext);
    setEventImages(images);

    // Combined interval for both reviews and cards
    const combinedInterval = setInterval(() => {
      // Start fade out
      setIsChanging(true);
      
      // Wait for fade out to complete before updating
      const updateTimeout = setTimeout(() => {
        // Update all content at once using previous state
        setCurrentReview(prev => (prev + 1) % reviews.length);
        setQuestionCard(prev => (prev % TOTAL_QUESTIONS) + 1);
        setAnswerCards(prev => prev.map(card => (card % TOTAL_ANSWERS) + 1));
        
        // Start fade in
        setTimeout(() => setIsChanging(false), 50);
      }, 500);
      
      return () => clearTimeout(updateTimeout);
    }, 20000); // Much longer interval to reduce frequency

    return () => {
      clearTimeout(cardTimer);
      clearInterval(combinedInterval);
    };
  }, []); // No dependencies needed since we use functional updates

  const replaceCard = (type, index) => {
    if (type === 'question') {
      setQuestionCard(prevCard => (prevCard % TOTAL_QUESTIONS) + 1);
    } else {
      setAnswerCards(prevCards => {
        const newCards = [...prevCards];
        newCards[index] = (newCards[index] % TOTAL_ANSWERS) + 1;
        return newCards;
      });
    }
  };

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
    // Optional: Toggle body scroll
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && 
          !event.target.closest('.menu-icon') && 
          !event.target.closest('.menu-dropdown') &&
          !event.target.closest('.menu-items') &&
          !event.target.closest('.menu-item')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const HomeContent = () => (
    <div className="App">
      <div style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        zIndex: 1
      }}></div>
      <div style={{ position: "relative", zIndex: 2 }}>
        <div className="motto-container">
          <div className="motto-italian">tuttO é possibile</div>
        </div>
        <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuClick}>
          <div className="middle-line"></div>
        </div>
        <div 
          className={`menu-overlay ${isMenuOpen ? 'open' : ''}`}
          onClick={(e) => {
            // Only close if clicking directly on the overlay (not on menu items)
            if (e.target.classList.contains('menu-overlay')) {
              setIsMenuOpen(false);
            }
          }}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/Events/IMG_3573.jpeg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <div className="menu-items" style={{
            backgroundColor: 'transparent',
            borderRadius: '12px',
            padding: '20px 15px',
            boxShadow: 'none',
            width: '85%',
            maxWidth: '360px',
            marginTop: '200px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <div className="menu-title" style={{ color: '#fff', marginBottom: '15px', fontSize: '18px' }}>tuttO menu</div>
            
            <button 
              className="menu-item" 
              style={{
                "--item-index": 0,
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                padding: '10px 15px',
                borderRadius: '8px',
                marginBottom: '8px',
                border: 'none',
                width: '100%',
                textAlign: 'center',
                fontSize: '15px',
                fontWeight: '500',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                color: '#ffffff'
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.95)';
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.3)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.85)';
                e.currentTarget.style.boxShadow = 'none';
              }}
              onClick={() => {
                handleBuyNowClick();
                setIsMenuOpen(false);
              }}
            >
              <span>buy tuttO</span>
            </button>
            
            <button 
              className="menu-item"
              style={{
                "--item-index": 1,
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                padding: '10px 15px',
                borderRadius: '8px',
                marginBottom: '8px',
                border: 'none',
                width: '100%',
                textAlign: 'center',
                fontSize: '15px',
                fontWeight: '500',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                color: '#ffffff'
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.95)';
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.3)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.85)';
                e.currentTarget.style.boxShadow = 'none';
              }}
              onClick={() => {
                setIsGameNightModalOpen(true);
                setIsMenuOpen(false);
              }}
            >
              <span>Join Game Nights</span>
            </button>
          </div>
        </div>
        
        <div className="buttons-container" style={{ 
          position: 'relative',
          marginTop: '5vh',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '15px',
          width: '90%',
          maxWidth: '600px'
        }}>
          <button 
            className="buy-now-button" 
            onClick={handleBuyNowClick}
            style={{
              fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
              backgroundColor: "rgba(0, 0, 0, 0.85) !important",
              color: "#ffffff !important",
              border: "none",
              padding: "10px 20px",
              borderRadius: "8px",
              fontSize: "16px",
              letterSpacing: "0.5px",
              boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
              transition: "all 0.3s ease",
              position: "relative",
              overflow: "hidden",
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: '45%',
              maxWidth: '200px'
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.95) !important";
              e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.3)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.85) !important";
              e.currentTarget.style.boxShadow = "0 2px 6px rgba(0,0,0,0.2)";
            }}
          >
            buy tuttO
          </button>
          <button 
            className="game-night-button"
            onClick={() => setIsGameNightModalOpen(true)}
            style={{
              fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
              padding: "10px 20px",
              borderRadius: "8px",
              fontSize: "16px",
              letterSpacing: "0.5px",
              backgroundColor: "rgba(0, 0, 0, 0.85) !important",
              border: "none",
              color: "#ffffff !important",
              boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
              transition: "all 0.3s ease",
              position: "relative",
              overflow: "hidden",
              fontWeight: "500",
              textTransform: "lowercase",
              width: '45%',
              maxWidth: '200px'
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.95) !important";
              e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.3)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.85) !important";
              e.currentTarget.style.boxShadow = "0 2px 6px rgba(0,0,0,0.2)";
            }}
          >
            join invite list
          </button>
        </div>
        
        <div className="brands-outer-container" style={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: '#fff',
          padding: '10px 15px',
          borderRadius: '12px',
          textAlign: 'center',
          marginTop: '35vh',
          width: '90%',
          maxWidth: '500px',
          display: 'flex',
          flexDirection: 'column',
          gap: '0',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
          <h2 style={{ 
            fontSize: '1rem', 
            fontWeight: 'normal', 
            margin: '0',
            marginBottom: '10px',
            padding: '0',
            lineHeight: '1.2'
          }}>
            We partner with exceptional brands ~ spice up your next event with tuttO
          </h2>
          <div className="brands-logos-container" style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '15px',
            padding: '0',
            margin: '0'
          }}>
            <img src="/brands/logo1.png" alt="Brand 1" className="brand-logo" style={{
              height: '25px',
              filter: 'brightness(0) invert(1)',
              opacity: '0.8',
              transition: 'all 0.3s ease'
            }} />
            <img src="/brands/logo2.png" alt="Brand 2" className="brand-logo" style={{
              height: '25px',
              filter: 'brightness(0) invert(1)',
              opacity: '0.8',
              transition: 'all 0.3s ease'
            }} />
            <img src="/brands/logo3.png" alt="Brand 3" className="brand-logo" style={{
              height: '25px',
              filter: 'brightness(0) invert(1)',
              opacity: '0.8',
              transition: 'all 0.3s ease'
            }} />
            <img src="/brands/logo5.png" alt="Brand 5" className="brand-logo" style={{
              height: '25px',
              filter: 'brightness(0) invert(1)',
              opacity: '0.8',
              transition: 'all 0.3s ease'
            }} />
          </div>
        </div>

        {/* Instagram Embeds */}
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '30px',
          marginTop: '40px',
          marginBottom: '40px'
        }}>
          <div style={{
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto'
          }}>
            <iframe
              title="Instagram Post from The Ned NoMad"
              src="https://www.instagram.com/p/DFGB6bJI6nW/embed/captioned/?cr=1&v=14&rd=https%3A%2F%2Ftutto.play"
              width="100%"
              height="720"
              frameBorder="0"
              scrolling="no"
              allowtransparency="true"
              style={{
                background: '#FFF',
                borderRadius: '12px',
                margin: '20px 0',
                maxWidth: '100%',
                overflow: 'hidden'
              }}
            ></iframe>
          </div>
          <div style={{
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto'
          }}>
            <iframe
              title="Instagram Post from tuttO Play"
              src="https://www.instagram.com/p/DHuK_Z1OdOz/embed/captioned/?cr=1&v=14&rd=https%3A%2F%2Ftutto.play"
              width="100%"
              height="720"
              frameBorder="0"
              scrolling="no"
              allowtransparency="true"
              style={{
                background: '#FFF',
                borderRadius: '12px',
                margin: '20px 0',
                maxWidth: '100%',
                overflow: 'hidden'
              }}
            ></iframe>
          </div>
        </div>

        {/* Social Media Links and Copyright */}
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '40px',
          marginBottom: '20px'
        }}>
          <div style={{
            display: 'flex',
            gap: '30px',
            marginBottom: '20px',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <a 
              href="https://www.instagram.com/tuttO.play" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <svg width="20" height="20" viewBox="0 0 24 24" fill="white">
                <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
              </svg>
            </a>
            <a 
              href="https://open.spotify.com/artist/0UqQ8WagmEMLUFpTQJDwBC" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <svg width="20" height="20" viewBox="0 0 24 24" fill="white">
                <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
              </svg>
            </a>
            <a 
              href="https://music.apple.com/us/artist/tuttO/1803840779" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img 
                src="/applemusic.png" 
                alt="Apple Music" 
                style={{
                  height: '20px',
                  width: '20px',
                  objectFit: 'contain'
                }}
              />
            </a>
            <a 
              href="mailto:adam@tuttoplay.com"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <svg width="20" height="20" viewBox="0 0 24 24" fill="white">
                <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
              </svg>
            </a>
            <a 
              href="https://www.linkedin.com/company/tuttoplay"
              target="_blank" 
              rel="noopener noreferrer"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <svg width="20" height="20" viewBox="0 0 24 24" fill="white">
                <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.68 1.68 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"/>
              </svg>
            </a>
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '20px'
          }}>
            <img 
              src="/whitelogo.png" 
              alt="tuttO Logo" 
              style={{
                height: '20px',
                marginRight: '5px'
              }}
            />
            <span style={{
              color: '#ffffff',
              fontSize: '14px',
              opacity: '0.8'
            }}>
              2025 tuttO
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Routes>
        <Route path="/game" element={(
          <div className="App">
            <div style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              zIndex: 1
            }}></div>
            <div style={{ position: "relative", zIndex: 2 }}>
              <div className="motto-container">
                <div className="motto-italian">tuttO é possibile</div>
              </div>
              <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuClick}>
                <div className="middle-line"></div>
              </div>
              <div 
                className={`menu-overlay ${isMenuOpen ? 'open' : ''}`}
                onClick={(e) => {
                  // Only close if clicking directly on the overlay (not on menu items)
                  if (e.target.classList.contains('menu-overlay')) {
                    setIsMenuOpen(false);
                  }
                }}
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/Events/IMG_3573.jpeg)`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="menu-items" style={{
                  backgroundColor: 'transparent',
                  borderRadius: '12px',
                  padding: '20px 15px',
                  boxShadow: 'none',
                  width: '85%',
                  maxWidth: '360px',
                  marginTop: '200px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <div className="menu-title" style={{ color: '#fff', marginBottom: '15px', fontSize: '18px' }}>tuttO menu</div>
                  
                  <button 
                    className="menu-item" 
                    style={{
                      "--item-index": 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.85)',
                      padding: '10px 15px',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      border: 'none',
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '15px',
                      fontWeight: '500',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                      color: '#ffffff'
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.95)';
                      e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.3)';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.85)';
                      e.currentTarget.style.boxShadow = 'none';
                    }}
                    onClick={() => {
                      handleBuyNowClick();
                      setIsMenuOpen(false);
                    }}
                  >
                    <span>buy tuttO</span>
                  </button>
                  
                  <button 
                    className="menu-item"
                    style={{
                      "--item-index": 1,
                      backgroundColor: 'rgba(0, 0, 0, 0.85)',
                      padding: '10px 15px',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      border: 'none',
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '15px',
                      fontWeight: '500',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                      color: '#ffffff'
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.95)';
                      e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.3)';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.85)';
                      e.currentTarget.style.boxShadow = 'none';
                    }}
                    onClick={() => {
                      setIsGameNightModalOpen(true);
                      setIsMenuOpen(false);
                    }}
                  >
                    <span>Join Game Nights</span>
                  </button>
                </div>
              </div>
              <div className="game-area">
                <div 
                  className={`question-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
                  onClick={() => replaceCard('question')}
                >
                  <img src={`/Questions/q${questionCard}.jpg`} alt="Question Card" />
                </div>
                <div className="answer-cards">
                  {answerCards.map((card, index) => (
                    <div
                      key={index}
                      className={`answer-card ${showCards ? 'show' : ''} ${isChanging ? 'changing' : ''}`}
                      style={{ animationDelay: `${index * 0.1}s` }}
                      onClick={() => replaceCard('answer', index)}
                    >
                      {index === 3 ? (
                        <div className="turn-on-card">
                          <img src="/turnon.jpg" alt="Turn On Card" />
                          <div className="turn-on-text">Write what turns you on</div>
                        </div>
                      ) : (
                        <img src={`/Answers/a${card}.jpg`} alt={`Answer Card ${index + 1}`} />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <h2 style={{ textAlign: 'center', maxWidth: '800px', margin: '0 auto', padding: '0 20px' }}>
                A late-night laugh has grown into tuttO—a band with a beat and a dream. We live by one rule: tuttO é possibile—anything's possible if you've got the guts to chase it. From flirting over a deck to strumming chords that hum with life, we've traded the shuffle for a sound that's all our own. Join the invite list and bring us along to your next party—we're the crew that turns a spark into a glow, the vibe that makes the room feel alive. No grand rebellion, just a bunch of folks who believe the good stuff happens when you let the moment sing.
              </h2>
              <div className="buttons-container" style={{ 
                position: 'relative',
                marginTop: '5vh',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '15px',
                width: '90%',
                maxWidth: '600px'
              }}>
                <button 
                  className="buy-now-button" 
                  onClick={handleBuyNowClick}
                  style={{
                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
                    backgroundColor: "rgba(0, 0, 0, 0.85) !important",
                    color: "#ffffff !important",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "8px",
                    fontSize: "16px",
                    letterSpacing: "0.5px",
                    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                    transition: "all 0.3s ease",
                    position: "relative",
                    overflow: "hidden",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: '45%',
                    maxWidth: '200px'
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.95) !important";
                    e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.3)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.85) !important";
                    e.currentTarget.style.boxShadow = "0 2px 6px rgba(0,0,0,0.2)";
                  }}
                >
                  buy tuttO
                </button>
                <button 
                  className="game-night-button"
                  onClick={() => setIsGameNightModalOpen(true)}
                  style={{
                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
                    padding: "10px 20px",
                    borderRadius: "8px",
                    fontSize: "16px",
                    letterSpacing: "0.5px",
                    backgroundColor: "rgba(0, 0, 0, 0.85) !important",
                    border: "none",
                    color: "#ffffff !important",
                    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                    transition: "all 0.3s ease",
                    position: "relative",
                    overflow: "hidden",
                    fontWeight: "500",
                    textTransform: "lowercase",
                    width: '45%',
                    maxWidth: '200px'
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.95) !important";
                    e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.3)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.85) !important";
                    e.currentTarget.style.boxShadow = "0 2px 6px rgba(0,0,0,0.2)";
                  }}
                >
                  join invite list
                </button>
              </div>
              <div className="game-explanation">
                <h2 className="game-rules">
                  The way to play:
                  <ul>
                    <li>Pick a Q Card: Dive into cheeky, provocative prompts.</li>
                    <li>Craft Your Turn-On: Write your wittiest, most seductive response.</li>
                    <li>Flirtbot to the Rescue: Feeling shy? Get a nudge with clever suggestions. 💡</li>
                    <li>Win Your Happy Ending: The most captivating answer wins the round!</li>
                  </ul>
                </h2>
                <p>tuttO isn't just a game—it's a moment. Unleash your charm, wit, and inner flirt in a battle for ultimate connection.</p>
              </div>
              
              <div className="laurel-container">
                <div 
                  className="laurel left" 
                  style={{ 
                    backgroundImage: 'url("/left.png")',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />
                <div className="review-container">
                  <p className="review-text">{reviews[currentReview].text}</p>
                </div>
                <div 
                  className="laurel right"
                  style={{ 
                    backgroundImage: 'url("/right.png")',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />
              </div>

              <div 
                className="product-image" 
                onClick={handleBuyNowClick}
              >
                <img src="/box.png" alt="tuttO a Game Box" />
              </div>

              <div className="game-explanation" style={{ marginTop: "20px" }}>
                <h2 className="game-rules">
                  <span className="game-title-text">the way to play</span> 
                  <ul>
                    <li>pick a <span className="game-title-text">Q</span> Card from a deck of cheeky &  <br /> deep prompts</li>
                    <li>craft your <span className="game-title-text">turnOn</span>~  write your wittiest, most seductive, or laugh-out-loud response</li>
                    <li>feeling shy? use the <span className="game-title-text">flirtbot</span> 💡 for guided flirtation</li>
                    <li> <span className="game-title-text">happy ending</span>~ the player with the most captivating, swoon-worthy, or sidesplitting flirts scores</li>
                  </ul>
                </h2>
              </div>

              <div className="brands-outer-container" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                color: '#fff',
                padding: '10px 15px',
                borderRadius: '12px',
                textAlign: 'center',
                marginTop: '35vh',
                width: '90%',
                maxWidth: '500px',
                display: 'flex',
                flexDirection: 'column',
                gap: '0',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}>
                <h2 style={{ 
                  fontSize: '1rem', 
                  fontWeight: 'normal', 
                  margin: '0',
                  marginBottom: '10px',
                  padding: '0',
                  lineHeight: '1.2'
                }}>
                  We partner with exceptional brands ~ spice up your next event with tuttO
                </h2>
                <div className="brands-logos-container" style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  gap: '15px',
                  padding: '0',
                  margin: '0'
                }}>
                  <img src="/brands/logo1.png" alt="Brand 1" className="brand-logo" style={{
                    height: '25px',
                    filter: 'brightness(0) invert(1)',
                    opacity: '0.8',
                    transition: 'all 0.3s ease'
                  }} />
                  <img src="/brands/logo2.png" alt="Brand 2" className="brand-logo" style={{
                    height: '25px',
                    filter: 'brightness(0) invert(1)',
                    opacity: '0.8',
                    transition: 'all 0.3s ease'
                  }} />
                  <img src="/brands/logo3.png" alt="Brand 3" className="brand-logo" style={{
                    height: '25px',
                    filter: 'brightness(0) invert(1)',
                    opacity: '0.8',
                    transition: 'all 0.3s ease'
                  }} />
                  <img src="/brands/logo5.png" alt="Brand 5" className="brand-logo" style={{
                    height: '25px',
                    filter: 'brightness(0) invert(1)',
                    opacity: '0.8',
                    transition: 'all 0.3s ease'
                  }} />
                </div>
              </div>

              <div style={{ width: '100%', overflow: 'hidden' }}>
                <div className="event-gallery">
                  {eventImages.map((image, index) => (
                    <img 
                      key={`event-${index}`}
                      src={image}
                      alt={`Event ${index + 1}`}
                      className="event-image"
                      loading="lazy"
                    />
                  ))}
                </div>
              </div>

              <div style={{
                fontSize: "24px",
                fontWeight: "bold",
                margin: "20px 0",
                textAlign: "center",
                fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif"
              }}>
                what turns you on?
              </div>

              <div className="buttons-container" style={{
                width: '90%',
                maxWidth: '300px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
                margin: '30px auto'
              }}>
                <button 
                  className="buy-now-button" 
                  onClick={handleBuyNowClick}
                  style={{
                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
                    backgroundColor: "rgba(0, 0, 0, 0.85) !important",
                    color: "#ffffff !important",
                    border: "none",
                    padding: "14px 36px",
                    borderRadius: "8px",
                    fontSize: "18px",
                    letterSpacing: "1px",
                    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                    transition: "all 0.3s ease",
                    position: "relative",
                    overflow: "hidden",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: '100%'
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.95) !important";
                    e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.3)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.85) !important";
                    e.currentTarget.style.boxShadow = "0 2px 6px rgba(0,0,0,0.2)";
                  }}
                >
                  buy tuttO
                </button>
                <button 
                  className="game-night-button"
                  onClick={() => setIsGameNightModalOpen(true)}
                  style={{
                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif",
                    padding: "14px 36px",
                    borderRadius: "8px",
                    fontSize: "18px",
                    letterSpacing: "1px",
                    backgroundColor: "rgba(0, 0, 0, 0.85) !important",
                    border: "none",
                    color: "#ffffff !important",
                    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                    transition: "all 0.3s ease",
                    position: "relative",
                    overflow: "hidden",
                    fontWeight: "500",
                    textTransform: "lowercase",
                    width: '100%'
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.95) !important";
                    e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.3)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.85) !important";
                    e.currentTarget.style.boxShadow = "0 2px 6px rgba(0,0,0,0.2)";
                  }}
                >
                  join tuttO event list
                </button>
              </div>

              <div className="social-links">
                <a 
                  href="https://www.instagram.com/tuttO.play" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="instagram-link"
                  onClick={() => {
                    window.open("https://www.instagram.com/tuttO.play", "_blank");
                  }}
                  style={{
                    fontSize: "32px",
                    color: "#000000",
                    marginTop: "15px",
                    display: "inline-block"
                  }}
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        )} />
        <Route path="/join" element={<HomeContent />} />
        <Route path="/" element={<HomeContent />} />
      </Routes>
      
      <BuyNowModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onBuyNow={handleModalBuyNow}
      />

      <GameNightSignupModal
        isOpen={isGameNightModalOpen}
        onClose={() => {
          setIsGameNightModalOpen(false);
          if (location.pathname === '/join') {
            window.history.pushState({}, '', '/');
          }
        }}
      />
    </>
  );
}

export default App;
import React from 'react';
import './BuyNowModal.css';

const BuyNowModal = ({ isOpen, onClose, onBuyNow }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        
        <div className="modal-body">
          <div className="modal-header">
            <img 
              src="/box2.png" 
              alt="Tutto Game Box" 
              className="modal-product-image"
            />
            <h2 className="modal-title">Get Ready for Tutto!</h2>
          </div>
          
          <div className="modal-details">
            <div className="compact-card-list">
              <div className="card-item">🌶️ 69 Spicy Q Cards</div>
              <div className="card-item">❤️ 220 Blush-Worthy Answer Cards</div>
              <div className="card-item">💋 TurnOn Cards to write your own turn-ons</div>
              <div className="card-item">💡 FlirtBot AI Suggestions</div>
            </div>
            
            <button className="modal-buy-button" onClick={onBuyNow}>
              <span className="button-text">buy now</span>
              <span className="button-icon">→</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyNowModal; 